'use client';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';

import { pushDataLayerEvent } from '@/helpers/client/analytics/data-layer';
import { isInStoryblok } from '@/helpers/client/storyblok';

import './index.css';

let globalPreferences;

/** @type {number} */
const COOKIE_CONSENT_REVISION = 9;

/**
 * @typedef {'necessary'|'functionality'|'analytics'|'marketing'} CookieConsentCategory
 */

/**
 * @enum {CookieConsentCategory}
 * @type {Object.<string, CookieConsentCategory>}
 */
export const COOKIE_CONSENT_CATEGORY = {
  /**
   * Strictly necessary cookies — These cookies are essential for you to browse
   * the website and use its features, such as accessing secure areas of the
   * site. Cookies that allow web shops to hold your items in your cart while
   * you are shopping online are an example of strictly necessary cookies. These
   * cookies will generally be first-party session cookies. While it is not
   * required to obtain consent for these cookies, what they do and why they are
   * necessary should be explained to the user.
   * @see {@link https://gdpr.eu/cookies/}
   */
  NECESSARY: 'necessary',

  /**
   * Preferences cookies — Also known as “functionality cookies,” these cookies
   * allow a website to remember choices you have made in the past, like what
   * language you prefer, what region you would like weather reports for, or
   * what your user name and password are so you can automatically log in.
   * @see {@link https://gdpr.eu/cookies/}
   */
  PREFERENCES: 'functionality',

  /**
   * Statistics cookies — Also known as “performance cookies,” these cookies
   * collect information about how you use a website, like which pages you
   * visited and which links you clicked on. None of this information can be
   * used to identify you. It is all aggregated and, therefore, anonymized.
   * Their sole purpose is to improve website functions. This includes cookies
   * from third-party analytics services as long as the cookies are for the
   * exclusive use of the owner of the website visited.
   * @see {@link https://gdpr.eu/cookies/}
   */
  STATISTICS: 'analytics',

  /**
   * Marketing cookies — These cookies track your online activity to help
   * advertisers deliver more relevant advertising or to limit how many times
   * you see an ad. These cookies can share that information with other
   * organizations or advertisers. These are persistent cookies and almost
   * always of third-party provenance.
   * @see {@link https://gdpr.eu/cookies/}
   */
  MARKETING: 'marketing',
};

/**
 * @type {CookieConsentCategory[]}
 */
export const COOKIE_CONSENT_CATEGORIES = [
  COOKIE_CONSENT_CATEGORY.NECESSARY,
  COOKIE_CONSENT_CATEGORY.PREFERENCES,
  COOKIE_CONSENT_CATEGORY.STATISTICS,
  COOKIE_CONSENT_CATEGORY.MARKETING,
];

/**
 * Available Google Tag Manager cookie consent keys:
 *  - ad_storage
 *  - ad_user_data
 *  - ad_personalization
 *  - analytics_storage
 *  - functionality_storage
 *  - personalization_storage
 *  - security_storage
 */
const GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP = {
  [COOKIE_CONSENT_CATEGORY.PREFERENCES]: ['personalization_storage'],
  [COOKIE_CONSENT_CATEGORY.STATISTICS]: ['analytics_storage'],
  [COOKIE_CONSENT_CATEGORY.MARKETING]: [
    'ad_storage',
    'ad_user_data',
    'ad_personalization',
  ],
};

const generateCookieNameList = (input) => {
  return input.join('<br/>');
};

export const markdownToHtml = (input) => {
  input = input.replace(/(?:\r\n|\r|\n)/g, '<br>');
  const elements = input.match(/\[.*?\)/g);
  if (elements != null && elements.length > 0) {
    for (const el of elements) {
      let txt = el.match(/\[(.*?)\]/)[1];
      let url = el.match(/\((.*?)\)/)[1];
      input = input.replace(
        el,
        '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          txt +
          '</a>',
      );
    }
  }

  return input;
};

const updateGoogleTagManagerConsentState = () => {
  if (typeof window !== 'undefined') {
    const consentState = Object.values(COOKIE_CONSENT_CATEGORIES).reduce(
      (acc, category) => {
        if (
          Array.isArray(GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP[category])
        ) {
          return GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP[category].reduce(
            (itemAcc, item) => {
              return {
                ...itemAcc,
                [item]: globalPreferences.acceptedCategories.includes(category)
                  ? 'granted'
                  : 'denied',
              };
            },
            acc,
          );
        }

        return acc;
      },
      {},
    );

    window?.gtag?.('consent', 'update', consentState);

    pushDataLayerEvent({
      event: 'consent_update',
    });
  }
};

const handleCookieConsentUpdate = () => {
  const preferences = VanillaCookieConsent.getUserPreferences?.();

  globalPreferences = preferences;

  if (!(globalPreferences && globalPreferences.acceptedCategories)) {
    return;
  }

  updateGoogleTagManagerConsentState();
};

const logConsent = () => {
  const cookie = VanillaCookieConsent.getCookie?.();
  const preferences = VanillaCookieConsent.getUserPreferences?.();

  Sentry.withScope((scope) => {
    scope.setExtras({
      consentId: cookie.consentId,
      acceptType: preferences.acceptType,
      acceptedCategories: preferences.acceptedCategories,
      rejectedCategories: preferences.rejectedCategories,
    });

    scope.setTag('acceptType', preferences.acceptType);

    for (const category of COOKIE_CONSENT_CATEGORIES) {
      scope.setTag(
        category,
        preferences.acceptedCategories.includes(category) ? true : false,
      );
    }

    Sentry.captureMessage('Cookie consent');
  });
};

export const CookieConsent = ({ locale }) => {
  useEffect(() => {
    if (
      !document.getElementById('cc-main') &&
      !isInStoryblok() &&
      typeof window !== 'undefined' &&
      typeof VanillaCookieConsent?.run === 'function'
    ) {
      VanillaCookieConsent?.run?.({
        autoShow: true,
        hideFromBots: true,
        mode: 'opt-in',
        revision: COOKIE_CONSENT_REVISION,

        cookie: {
          name: 'cookie_consent',
          expiresAfterDays: 365,
          sameSite: 'Lax',
          path: '/',
        },

        guiOptions: {
          consentModal: {
            // transition: 'slide',
            layout: 'box inline',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false,
          },
          preferencesModal: {
            layout: 'box',
            position: 'right',
            equalWeightButtons: true,
            flipButtons: false,
          },
        },

        categories: {
          necessary: {
            readOnly: true,
          },
          functionality: {
            autoClear: {
              cookies: [],
            },
          },
          analytics: {
            autoClear: {
              cookies: [
                {
                  name: /^(_ga|_gid)/,
                },
                {
                  name: /^_hj/,
                },
              ],
            },
          },
          marketing: {
            autoClear: {
              cookies: [
                {
                  name: /^cb_/,
                },
              ],
            },
          },
        },

        onFirstConsent() {
          handleCookieConsentUpdate();

          logConsent();
        },

        onAccept() {
          handleCookieConsentUpdate();
        },

        onChange() {
          handleCookieConsentUpdate();

          logConsent();
        },

        onConsent: () => {
          handleCookieConsentUpdate();
        },

        language: {
          default: locale?.shortCode,
          translations: {
            fr: {
              consentModal: {
                title: 'Gérer les cookies',
                description: `Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui.`,
                acceptAllBtn: 'Tout accepter',
                acceptNecessaryBtn: 'Tout rejeter',
                showPreferencesBtn: 'Gérer les préférences',
                footer:
                  '<a href="/mentions-legales">Politique de confidentialité</a>\n<a href="/mentions-legales">Termes et conditions</a>',
              },
              preferencesModal: {
                title: 'Préférences de cookies',
                acceptAllBtn: 'Tout accepter',
                acceptNecessaryBtn: 'Tout rejeter',
                savePreferencesBtn: 'Sauvegarder les préférences',
                closeIconLabel: 'Fermer la modale',
                serviceCounterLabel: 'Services',
                sections: [
                  {
                    title: 'Utilisation des cookies',
                    description:
                      'Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui.',
                  },
                  {
                    title:
                      'Cookies strictement nécessaires <span class="pm__badge">Toujours activé</span>',
                    description:
                      'Ces cookies sont essentiels au bon fonctionnement de notre site Web. Sans ces cookies, le site Web ne peut pas fonctionner correctement.',
                    linkedCategory: 'necessary',
                    cookieTable: {
                      caption: 'Cookies',
                      headers: {
                        name: 'Noms des cookies',
                        domain: 'Domaine',
                        desc: 'Usage',
                      },
                      body: [
                        {
                          name: generateCookieNameList(['cookie_consent']),
                          domain: location.hostname,
                          desc: markdownToHtml(
                            `Cookie utilisé pour enregistrer les préférences d'utilisation des cookies du site web ${process.env.HOST}.`,
                          ),
                        },
                      ],
                    },
                  },
                  {
                    title: 'Cookies de préférences',
                    description:
                      "Ces cookies permettent à ce site web de se souvenir des choix que vous avez faits dans le passé, comme la langue que vous préférez, la région pour laquelle vous souhaitez recevoir des bulletins météorologiques ou votre nom d'utilisateur et votre mot de passe afin que vous puissiez vous connecter automatiquement.",
                    linkedCategory: 'functionality',
                  },
                  {
                    title: 'Cookies statistiques',
                    description:
                      'Ces cookies recueillent des informations sur la façon dont vous utilisez le site Web, les pages que vous visitez et les liens sur lesquels vous cliquez.',
                    linkedCategory: 'analytics',
                    cookieTable: {
                      caption: 'Cookies',
                      headers: {
                        name: 'Noms des cookies',
                        domain: 'Domaine',
                        desc: 'Usage',
                      },
                      body: [
                        {
                          name: generateCookieNameList([
                            'gtm_id_ga',
                            '_ga',
                            '_gid',
                            'CONSENT',
                            'cid',
                            'NID',
                            'OGP',
                            'OGPC',
                            'OTZ',
                            '_gcl_au',
                            'ANID',
                            'APISID',
                            'SAPISID',
                            'SIDCC',
                            'SSID',
                            '1P_JAR',
                            'HSID',
                            'S',
                            'DV',
                            'SEARCH_SAMESITE',
                            'SID',
                            '__Secure-1PAPISID',
                            '__Secure-1PSID',
                            '__Secure-1PSIDCC',
                            '__Secure-3PAPISID',
                            '__Secure-3PSID',
                            '__Secure-3PSIDCC',
                          ]),
                          domain: location.hostname,
                          desc: markdownToHtml(
                            'Nous utilisons les services de Google pour analyser la façon dont les visiteurs utilisent notre site Web. Voir [les règles de confidentialité de Google](https://policies.google.com/privacy).',
                          ),
                        },
                        {
                          name: generateCookieNameList([
                            '_hjSessionUser*',
                            '_hjSession*',
                            '_hjClosedSurveyInvites',
                            '_hjDonePolls',
                            '_hjMinimizedPolls',
                            '_hjShownFeedbackMessage',
                            '_hjSessionTooLarge',
                            '_hjSessionRejected',
                            '_hjSessionResumed',
                            '_hjid',
                            '_hjRecordingLastActivity',
                            '_hjTLDTest',
                            '_hjUserAttributesHash',
                            '_hjCachedUserAttributes',
                            '_hjLocalStorageTest',
                            '_hjIncludedInPageviewSample',
                            '_hjIncludedInSessionSample',
                            '_hjAbsoluteSessionInProgress',
                            '_hjFirstSeen',
                            '_hjViewportId',
                            '_hjRecordingEnabled',
                          ]),
                          domain: location.hostname,
                          desc: markdownToHtml(
                            'Nous utilisons Hotjar pour analyser le comportement des visiteurs sur notre site Web. Voir [la politique de confidentialité de Hotjar](https://help.hotjar.com/hc/en-us/sections/360007966773-Privacy) et [les informations sur les cookies de Hotjar](https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information).',
                          ),
                        },
                      ],
                    },
                  },
                  {
                    title: 'Cookies de marketing',
                    description:
                      "Ces cookies suivent votre activité en ligne afin de vous partager des publicités plus pertinentes ou pour limiter le nombre de fois où vous voyez une publicité. Ces cookies peuvent partager ces informations avec d'autres organisations ou annonceurs.",
                    linkedCategory: 'marketing',
                    cookieTable: {
                      caption: 'Cookies',
                      headers: {
                        name: 'Noms des cookies',
                        domain: 'Domaine',
                        desc: 'Usage',
                      },
                      body: [
                        {
                          name: generateCookieNameList([
                            'cb_user_id',
                            'cb_group_id',
                            'cb_anonymous_id',
                            'cb_*',
                          ]),
                          domain: location.hostname,
                          desc: markdownToHtml(
                            'Nous utilisons Clearbit pour le reciblage publicitaire. Voir [la politique de confidentialité de Clearbit](https://clearbit.com/privacy).',
                          ),
                        },
                        {
                          name: generateCookieNameList(['IDEDSID', '__ar_v4']),
                          domain: location.hostname,
                          desc: markdownToHtml(
                            'Nous utilisons les services de Google Ads pour suivre les conversions et les visites de notre site Web faites depuis des publicités. Google Ads, voir [les règles de confidentialité de Google](https://policies.google.com/privacy).',
                          ),
                        },
                        {
                          name: generateCookieNameList([
                            'lang',
                            'sdsc',
                            'lms_ads',
                            'lms_analytics',
                            'lidc',
                            'liap',
                            'AnalyticsSyncHistory',
                            'UserMatchHistory',
                            'bcookie',
                            'li_mc',
                            'AMCV_*',
                          ]),
                          desc: markdownToHtml(
                            'Nous utilisons LinkedIn Ads pour le reciblage publicitaire. Voir [la politique de confidentialité de LinkedIn Ads](https://www.linkedin.com/legal/sas-terms)',
                          ),
                        },
                      ],
                    },
                  },
                ],
              },
            },
            // en: {
            //   consentModal: {
            //     title: 'Manage cookies',
            //     description: `Our website uses essential cookies to ensure its proper functioning and tracking cookies to understand how you interact with it.`,
            //     acceptAllBtn: 'Accept all',
            //     acceptNecessaryBtn: 'Reject all',
            //     showPreferencesBtn: 'Manage preferences',
            //     footer:
            //       '<a href="/mentions-legales">Privacy Policy</a>\n<a href="/mentions-legales">Terms and conditions</a>',
            //   },
            //   preferencesModal: {
            //     title: 'Consent Preferences Center',
            //     acceptAllBtn: 'Accept all',
            //     acceptNecessaryBtn: 'Reject all',
            //     savePreferencesBtn: 'Save preferences',
            //     closeIconLabel: 'Close modal',
            //     serviceCounterLabel: 'Service|Services',
            //     sections: [
            //       {
            //         title: 'Cookie Usage',
            //       },
            //       {
            //         title:
            //           'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
            //         linkedCategory: 'necessary',
            //       },
            //       {
            //         title: 'Functionality Cookies',
            //         linkedCategory: 'functionality',
            //       },
            //       {
            //         title: 'Analytics Cookies',
            //         linkedCategory: 'analytics',
            //       },
            //       {
            //         title: 'Advertisement Cookies',
            //         linkedCategory: 'marketing',
            //       },
            //     ],
            //   },
            // },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    // FIXME:
    // if (window.CookieConsentApi && locale?.shortCode) {
    //   window.CookieConsentApi.updateLanguage(locale.shortCode, true);
    // }
  }, [locale]);

  return null;
};
